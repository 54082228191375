<ion-app>
  <ion-menu
    contentId="main-content"
    class="site-menu"
  >

    <ion-content>
      <div class="background"></div>
      <div class="background2"></div>
      <ion-list id="inbox-list">
        <ion-list-header
          class="norse-font"
          style="font-size: 40px;"
        >ODIN</ion-list-header>
        <ion-note class="norse-font">Ludopub</ion-note>
        <ion-menu-toggle
          auto-hide="true"
          *ngFor="let p of appPages$ | async; let i = index"
        >
          <ion-item
            button
            routerDirection="root"
            [routerLink]="[p.url]"
            lines="none"
            detail="true"
            routerLinkActive="selected"
          >
            <ion-icon
              slot="start"
              [ios]="p.icon"
              [md]="p.icon"
            ></ion-icon>
            <ion-label>{{ p.title }}</ion-label>
          </ion-item>
        </ion-menu-toggle>
      </ion-list>
    </ion-content>
  </ion-menu>


  <div
    class="ion-page"
    id="main-content"
  >
    <ion-router-outlet></ion-router-outlet>
  </div>

  <ion-loading></ion-loading>
</ion-app>